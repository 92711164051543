import Helmet from "react-helmet"
import React from "react"
import HomepageLayout from "src/layouts/homepage"
import GWlp from "src/components/gwlp"
import Btf_carbonate from "src/components/btf_carbonate"

const GWlpData = import("./data/data.json");
const btfData = import("./data/btfData.json");

export default function prdbest() {

  return(
    <HomepageLayout>
    <Helmet>
    <title>Get Curated Content and Apps - Carbonate</title>
    </Helmet>
    <section>
      <GWlp data={GWlpData}></GWlp>
      <Btf_carbonate data={btfData}></Btf_carbonate>
      </section>
    </HomepageLayout>
    
  )
  }
